import { StepItem } from "components/steplist";
import { RefundMessage, RefundStatus } from "data/refund";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import DisbursementTable from "./disbursementTable";
import { useDisbursementInfo } from "./useDisburmentInfo";
import { useIconType } from "./useIconType";

export const useEFiledStep = () => {
  const [t] = useTranslation("messages");
  return useCallback(
    (refundStatus: RefundStatus): StepItem => {
      return {
        iconType: "checkmark",
        title: t(`eFiled.title`, refundStatus),
        message: t(`eFiled.message`, refundStatus),
        date: "",
        moreDetailsText: t(`eFiled.moreDetails`, refundStatus),
      };
    },
    [t],
  );
};

export const useEFileAcceptedStep = () => {
  const [t] = useTranslation("messages");
  return useCallback(
    (refundStatus: RefundStatus): StepItem => {
      return {
        iconType: "checkmark",
        title: t(`eFileAccepted.title`, refundStatus),
        message: t(`eFileAccepted.message`, refundStatus),
        date: "",
        moreDetailsText: t(`eFileAccepted.moreDetails`, refundStatus),
      };
    },
    [t],
  );
};

export const useAnticipatedRefundStep = () => {
  const [t] = useTranslation("messages");
  const getDisbursementInfo = useDisbursementInfo();
  const getIconType = useIconType("hourglass");
  return useCallback(
    (refundStatus: RefundStatus, type: "state" | "federal"): StepItem | null => {
      const refund = refundStatus[type];
      const pending = new Set([
        RefundMessage.StatePastAnticipatedDate,
        RefundMessage.StatePendingAnticipatedDate,
        RefundMessage.FederalPastAnticipatedDate,
        RefundMessage.FederalPastAnticipatedDatePathAct,
        RefundMessage.FederalPendingAnticipatedDate,
        RefundMessage.FederalPendingAnticipatedDatePathAct,
        RefundMessage.FederalPresentmentDate,
        RefundMessage.FederalPresentmentDateForSpruceCustomers,
      ]);
      if (!refund || !pending.has(refund.messageNumber)) {
        return null;
      }

      return {
        iconType: getIconType(refund),
        title: t(`${refund.messageNumber}.title`, refundStatus),
        message: t(`${refund.messageNumber}.message`, refundStatus),
        date: t(`${refund.messageNumber}.date`, refundStatus),
        moreDetailsText: getDisbursementInfo(refund),
      };
    },
    [getDisbursementInfo, getIconType, t],
  );
};

export const useCompletedRefundStep = () => {
  const [t] = useTranslation("messages");
  const getDisbursementInfo = useDisbursementInfo();
  const getIconType = useIconType("checkmark");

  return useCallback(
    (refundStatus: RefundStatus, type: "state" | "federal"): StepItem | null => {
      const refund = refundStatus[type];
      const completed = new Set([
        RefundMessage.StateReceivedAfterFed,
        RefundMessage.StateReceivedAfterFedLowerAmount,
        RefundMessage.StateReceivedBeforeFed,
        RefundMessage.StateReceivedBeforeFedLowerAmount,
        RefundMessage.StateFlipToCheck,
        RefundMessage.FederalReceived,
        RefundMessage.FederalReceivedLowerAmount,
        RefundMessage.FederalFlipToCheck,
      ]);
      if (!refund || !completed.has(refund.messageNumber)) {
        return null;
      }
      return {
        iconType: getIconType(refund),
        title: t(`${refund.messageNumber}.title`, refundStatus),
        message: t(`${refund.messageNumber}.message`, refundStatus),
        date: t(`${refund.messageNumber}.date`, refundStatus),
        moreDetailsText: getDisbursementInfo(refund),
        dispersementChart: <DisbursementTable refundInfo={refund} />,
      };
    },
    [getDisbursementInfo, getIconType, t],
  );
};
