import Card, { CardProps } from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { startOfToday } from "date-fns";
import isToday from "date-fns/isToday";
import ChevronDown from "icons/ChevronDown";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = CardProps & {
  className?: string;
  lastUpdate?: Date;
};

const BannerCard = styled(Card)(({ theme }) => ({ backgroundColor: theme.palette.grey[100] }));
const BannerTitle = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: theme.typography.fontWeightBold,
}));
const BannerText = styled(Typography)(({ theme }) => ({
  textAlign: "center",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
}));

const LastUpdateBanner: React.FC<Props> = ({ className, lastUpdate, sx, ...props }) => {
  const [t] = useTranslation();
  const today = startOfToday();
  const isUpdatedToday = lastUpdate && isToday(lastUpdate);
  const title = isUpdatedToday ? t("newUpdatesToday") : t("lastUpdateCheck", { today });
  const message = isUpdatedToday ? (
    <Trans i18nKey="seeBelow">
      See below <ChevronDown />
    </Trans>
  ) : (
    t("lastUpdateMessage")
  );

  return (
    <BannerCard sx={sx} {...props}>
      <CardContent
        sx={(theme) => ({
          "&:last-child": {
            paddingBottom: "1rem",
          },
          "& .MuiTypography-h2": {
            marginBottom: "0.75rem",
          },
          "& .MuiTypography-body2": {
            color: theme.palette.grey[600],
            whiteSpace: "pre-line",
          },
        })}
      >
        <BannerTitle data-testid="last-update-banner-title" variant="h2">
          {title}
        </BannerTitle>
        <BannerText data-testid="last-update-banner-message" variant="body2">
          {message}
        </BannerText>
      </CardContent>
    </BannerCard>
  );
};

export default LastUpdateBanner;
