import MuiLink from "@mui/material/Link";
import { alpha, Theme } from "@mui/material/styles";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LinkProps = any; // TODO: I can't seem to make Typescript happy, this should be whatever props MuiLink takes

type Props = LinkProps & {
  endIcon?: React.ReactNode;
};

const Link: React.FC<Props> = ({ endIcon, underline, color, sx, children, ...props }) => {
  const underlineStyle = (color: string, theme: Theme) => {
    if (color === "primary" || "secondary" || "error") {
      const shadowColor =
        color === "primary"
          ? theme.palette.primary.main
          : color === "secondary"
            ? theme.palette.secondary.main
            : theme.palette.error.main;
      return {
        cursor: "pointer",
        fontWeight: theme.typography.fontWeightBold,
        transition: "all .2s ease-in-out",
        "box-shadow": `inset 0 -0.375rem 0 ${alpha(shadowColor, 0.4)}`,
        "&:hover": {
          "text-decoration": "none",
          "box-shadow": `inset 0 -4.0625rem 0 ${shadowColor}`,
        },
      };
    } else {
      return;
    }
  };

  return (
    <MuiLink
      sx={(theme) => ({
        ...sx,
        "&.MuiLink-underlineHover": {
          color: theme.palette.common.black,
          "& span": {
            ...underlineStyle(color, theme),
          },
        },
      })}
      underline={underline ?? "hover"}
      color
      {...props}
    >
      {children && <span>{children}</span>}
      {endIcon}
    </MuiLink>
  );
};

export default Link;
