import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import React from "react";

type Props = {
  label: string;
  message?: string;
  icon?: React.ReactNode;
};

const DisbursmentFlagContainer = styled("div")();

const DisbursmentFlag: React.FC<Props> = ({ label, message, icon }) => {
  const hasMessageOrIcon = message || icon;

  return (
    <DisbursmentFlagContainer
      sx={(theme) => ({
        padding: "0.25rem 2rem 0.25rem 0.5rem",
        display: "inline-block",
        position: "relative",
        overflow: "hidden",
        lineHeight: "1rem",
        "&:before": {
          content: "''",
          transformOrigin: "top right",
          transform: "skewX(-15deg)",
          position: "absolute",
          background: hasMessageOrIcon ? theme.palette.grey[900] : theme.palette.grey[600],
          left: "-45px",
          height: "100%",
          right: "0",
          top: "0",
        },
        "&:after": {
          content: "''",
          position: "absolute",
          left: "-45px",
          height: "100%",
          right: "0",
          top: "0",
          transformOrigin: "bottom right",
          transform: "skewX(15deg)",
          background: hasMessageOrIcon ? theme.palette.grey[900] : theme.palette.grey[600],
        },
        "& > p": {
          position: "relative",
          zIndex: 10,
        },
        ...(!hasMessageOrIcon && {
          paddingTop: "0.6875rem",
          paddingBottom: "0.6875rem",
        }),
      })}
    >
      <Typography
        sx={(theme) => ({
          color: theme.palette.common.white,
          fontSize: "0.75rem",
          fontWeight: theme.typography.fontWeightBold,
          lineHeight: "1rem",
        })}
      >
        {label}
      </Typography>
      {hasMessageOrIcon && (
        <Typography
          sx={(theme) => ({
            color: theme.palette.primary.light,
            fontSize: "0.875rem",
            fontWeight: theme.typography.fontWeightBold,
            lineHeight: "1rem",
            display: "flex",
            "& > svg": {
              height: "1rem",
              marginLeft: "0.25rem",
            },
          })}
        >
          {message}
          {icon}
        </Typography>
      )}
    </DisbursmentFlagContainer>
  );
};

export default DisbursmentFlag;
