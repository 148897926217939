import Step from "@mui/material/Step";
import StepConnector from "@mui/material/StepConnector";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Arrow from "./arrow";
import BankCheck from "./bankCheck";
import Checkmark from "./checkmark";
import Hourglass from "./hourglass";
import Link from "./link";
import Warning from "./warning";

export type StepItem = {
  title: string;
  message: string;
  iconType: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation";
  dispersementChart?: React.ReactNode;
  moreDetailsText?: React.ReactNode;
  date: string;
};

type Props = {
  list: StepItem[];
  showConnector?: boolean;
};

const StepList: React.FC<Props> = ({ list, showConnector }) => {
  const [t] = useTranslation();
  const [activeStep, setActiveStep] = useState<number[]>([]);

  const iconForType = (type: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation") => {
    switch (type) {
      case "checkmark":
        return Checkmark;
      case "hourglass":
      case "presentHourglass":
        return Hourglass;
      case "bankCheck":
        return BankCheck;
      case "exclamation":
        return Warning;
    }
  };

  const propsForType = (type: "checkmark" | "hourglass" | "presentHourglass" | "bankCheck" | "exclamation") => {
    switch (type) {
      case "checkmark":
        return { color: "primary" };
      case "hourglass":
        return { color: "secondary" };
      case "presentHourglass":
        return { color: "yellow" };
    }
  };

  return (
    <Stepper
      orientation="vertical"
      connector={
        <StepConnector
          sx={(theme) => ({
            paddingBottom: theme.spacing(0.5),
            ...(!(showConnector || showConnector === undefined) && {
              borderLeft: "none",

              "& .MuiStepConnector-lineVertical": {
                borderLeft: "none",
              },
            }),
          })}
        />
      }
    >
      {list.map(({ title, date, message, iconType, dispersementChart, moreDetailsText }, index) => (
        <Step active={true} key={title}>
          <StepLabel
            StepIconComponent={iconForType(iconType)}
            StepIconProps={propsForType(iconType)}
            sx={(theme) => ({ padding: theme.spacing(0, 0, 0.5) })}
            id={`step-label-${!(showConnector || showConnector === undefined) ? "anticipated" : "completed"}-${index}`}
            data-testid={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}-title`}
          >
            <Typography
              sx={(theme) => ({
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",

                "& .MuiTypography-caption": {
                  paddingLeft: "1rem",
                  textTransform: "uppercase",
                  flexShrink: 0,
                  fontWeight: theme.typography.fontWeightMedium,
                },
              })}
              variant="h2"
            >
              {title} {date && <Typography variant="caption">{date}</Typography>}
            </Typography>
          </StepLabel>
          <StepContent
            role="document"
            data-testid={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}-message`}
            aria-labelledby={`step-label-${
              !(showConnector || showConnector === undefined) ? "anticipated" : "completed"
            }-${index}`}
            sx={(theme) => ({
              "& .MuiCollapse-container": {
                paddingBottom: theme.spacing(0.5),
                borderBottom: `0.0625rem solid ${theme.palette.divider}`,
              },
              ...(!(showConnector || showConnector === undefined) && {
                borderLeft: "none",

                "& .MuiStepConnector-lineVertical": {
                  borderLeft: "none",
                },
              }),
            })}
          >
            <Typography variant="body2">{message}</Typography>
            {(moreDetailsText || dispersementChart) && (
              <Link
                sx={{
                  textAlign: "left",
                  margin: "0.5rem 0",
                  "& span": {
                    display: "inline-block",
                    minWidth: "5.05rem",
                  },
                  "& .MuiSvgIcon-root": {
                    verticalAlign: "middle",
                  },
                }}
                component="button"
                variant="body2"
                color="secondary"
                data-testid={`moredetails-link`}
                aria-expanded={activeStep.includes(index)}
                aria-controls={`more-details-${index}`}
                onClick={() =>
                  activeStep.includes(index)
                    ? setActiveStep(activeStep.filter((step) => step !== index))
                    : setActiveStep(activeStep.concat(index))
                }
                endIcon={<Arrow expand={activeStep.includes(index)} />}
              >
                {activeStep.includes(index) ? t("hideDetails") : t("moreDetails")}
              </Link>
            )}
            {activeStep.includes(index) && (
              <div id={`more-details-${index}`}>
                {moreDetailsText && <Typography variant="body2">{moreDetailsText}</Typography>}
                {dispersementChart}
              </div>
            )}
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
};

export default StepList;
