export type StateCode = string;

export enum RefundMessage {
  StateReceivedAfterFed = 4,
  StateReceivedAfterFedLowerAmount = 5,
  StatePastAnticipatedDate = 6,
  StateReceivedBeforeFed = 7,
  StatePendingAnticipatedDate = 8,
  StateFlipToCheck = 9,
  StateReceivedBeforeFedLowerAmount = 10,
  FederalReceived = 11,
  FederalReceivedLowerAmount = 12,
  FederalPastAnticipatedDate = 13,
  FederalPendingAnticipatedDate = 14,
  FederalPendingAnticipatedDatePathAct = 15,
  FederalPresentmentDate = 16,
  FederalPresentmentDateForSpruceCustomers = 22,
  FederalFlipToCheck = 17,
  FederalPastAnticipatedDatePathAct = 19,
}

export type Refund = {
  /**
   * The FIPS State Numeric Code this refund object applies to
   * https://www.census.gov/library/reference/code-lists/ansi/ansi-codes-for-states.html
   * In the case of an IRS Return stateCode will be undefined
   */
  stateCode?: StateCode;

  /**
   * Identifies the state of this particular refund
   */
  messageNumber: RefundMessage;

  /**
   * The anticipated refund amount.
   */
  anticipatedAmount: number;

  /**
   * The date the refund is anticiapted as ISO 8601 date string
   * this may not be available for all refund types
   * some stateCode will not be able to provide this data
   */
  anticipatedDate?: string;

  /**
   * The actual refund amount received from the tax agency
   */
  amountReceived?: number;

  /**
   * The date the actual refund amount is received as ISO 8601 date string
   */
  refundReceivedDate?: string;

  /**
   * The date the check was mailed as ISO 8601 date string
   */
  flipToCheckDate?: string;

  /**
   * The type of disburment that the person chose to have their refund dispensed
   * as
   */
  disbursementType?: DisbursementType;

  /**
   * An array of fees that H&R Block is charging the client
   */
  payments: Payment[];

  /**
   * Path act start date for federal refund
   */
  pathStartDate?: string;

  /**
   * Path act end date for federal refund
   */
  pathEndDate?: string;
};

export type DisbursementType = "emerald-card" | "direct-deposit" | "check" | "spruce-card" | "none";

export type PaymentChannel = "retail" | "diy";

export type Payment = {
  /**
   * Three digit fee identifier
   * This in conjunction with the channel uniquely identifies a fee
   * e.g 005
   */
  fid: string;
  /**
   * The amount associated with this payment
   */
  amount: number;
};

export type RefundStatus = {
  token: string;
  /**
   * The date of the most recent update to the refund status
   */
  parsedDate: string;
  /**
   * The tax filing date as ISO 8601 date string
   */
  eFiledDate: string;

  /**
   * The date the tax e-file was accepted as ISO 8601 date string
   */
  eFiledAcceptanceDate: string;

  /**
   * The type of fee
   */
  eFiledType: PaymentChannel;

  /**
   * if applicable the state refund data
   */
  state?: Refund;

  /**
   * federal refund data
   */
  federal?: Refund;

  /**
   * Date refund was updated
   */
  updatedDate?: string;

  /**
   * defines how the refund is disbursed and the fees associated with the
   * refund disbursement
   */
};

// {
//   "irs": "www.irs.gov/refunds",
//   "18": "http://....",
// }
export type RefundWebsiteContact = {
  [key: string]: string;
};

// {
//   "irs": "8008291040",
//   "18": "8001234567",
// }
export type RefundPhoneContact = {
  [key: string]: string;
};

export interface RefundState {
  loading: boolean;
  refund?: RefundStatus | null;
  error?: Error;
}

export interface RefundDispatch {
  getRefund: (token: string) => Promise<void>;
}
