import { createTheme } from "@mui/material/styles";
import {
  black,
  blue,
  darkGreen,
  darkGrey,
  green,
  greyBlue,
  lightGrey,
  mediumGrey,
  mediumWhite,
  neon,
  offBlack,
  offWhite,
  red,
  unselectedText,
  websiteLightGrey,
  white,
  yellow,
} from "./colors";

import "./fonts";
import { bold, medium, regular, semibold } from "./fonts";

const defaultTheme = createTheme({
  spacing: 16,
  palette: {
    mode: "light",
    common: {
      black,
      white,
    },
    text: {
      primary: offBlack,
      secondary: darkGrey,
    },
    primary: {
      light: neon,
      main: green,
      dark: darkGreen,
      contrastText: white,
    },
    secondary: {
      main: blue,
      contrastText: white,
    },
    warning: {
      main: yellow,
      contrastText: black,
    },
    grey: {
      50: offWhite,
      100: offWhite,
      200: mediumWhite,
      300: greyBlue,
      400: lightGrey,
      500: mediumGrey,
      600: websiteLightGrey,
      700: unselectedText,
      800: darkGrey,
      900: offBlack,
    },
    error: {
      main: red,
      contrastText: white,
    },
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 4.5,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: darkGrey,
      paper: white,
    },
  },
  typography: {
    fontFamily: ["Graphik", "Helvetica", "Arial", "sans-serif"].join(","),
    h1: {
      fontSize: "1.625rem",
      fontWeight: bold,
      lineHeight: "1.625rem",
    },
    h2: {
      fontSize: "1.125rem",
      fontWeight: semibold,
      lineHeight: "1.3125rem",
    },
    h3: {
      fontSize: "2.125rem",
      fontWeight: bold,
      lineHeight: "2.5rem",
    },
    body2: {
      color: darkGrey,
    },
    fontWeightRegular: regular,
    fontWeightMedium: medium,
    fontWeightBold: bold,
  },
  components: {
    MuiAppBar: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        root: {
          marginTop: "0.5rem",
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: white,
          color: offBlack,
          fontWeight: bold,
          "&.MuiToolbar-gutters": {
            padding: "0.5rem 1rem",
          },
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          "margin-left": "1rem",
          "padding-left": "1.75rem",
          "padding-right": "0",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          paddingRight: "0.75rem",
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        vertical: {
          "margin-left": "1rem",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          "& .MuiTableRow-root:last-child": {
            "& .MuiTableCell-root": {
              "border-bottom": "none",
            },
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          flexDirection: "row-reverse",
          paddingRight: ".5rem",
        },
      },
    },
  },
});

export default defaultTheme;
