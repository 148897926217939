import { Refund, RefundMessage } from "data/refund";
import { useCallback } from "react";

export const useIconType = (defaultIcon: "checkmark" | "hourglass" | "bankCheck" | "exclamation" = "checkmark") => {
  return useCallback(
    ({ messageNumber }: Refund) => {
      switch (messageNumber) {
        case RefundMessage.StateReceivedAfterFed:
        case RefundMessage.StateReceivedAfterFedLowerAmount:
        case RefundMessage.StateReceivedBeforeFed:
        case RefundMessage.StateReceivedBeforeFedLowerAmount:
        case RefundMessage.FederalReceived:
        case RefundMessage.FederalReceivedLowerAmount:
          return "checkmark";
        case RefundMessage.StatePendingAnticipatedDate:
        case RefundMessage.FederalPendingAnticipatedDate:
        case RefundMessage.FederalPendingAnticipatedDatePathAct:
          return "hourglass";
        case RefundMessage.FederalPresentmentDate:
        case RefundMessage.FederalPresentmentDateForSpruceCustomers:
          return "presentHourglass";
        case RefundMessage.StatePastAnticipatedDate:
        case RefundMessage.FederalPastAnticipatedDate:
        case RefundMessage.FederalPastAnticipatedDatePathAct:
          return "exclamation";
        case RefundMessage.FederalFlipToCheck:
        case RefundMessage.StateFlipToCheck:
          return "bankCheck";
        default:
          return defaultIcon;
      }
    },
    [defaultIcon],
  );
};
